<template>
  <div class="content">
    <!-- <div class="logo">后台管理系统</div> -->
    <el-menu class="el-menu-vertical-demo" text-color="#515a6e" active-text-color="#1890ff" router unique-opened
      :default-active="defaultActiveIndex">
      <el-menu-item index="user">会员管理</el-menu-item>
      <el-menu-item index="list">作品列表</el-menu-item>
      <el-menu-item index="matchManage">比赛管理</el-menu-item>
      <el-menu-item index="orderList">订单列表</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  created() {
    this.fetchData()
  },
  data() {
    return {
      defaultActiveIndex: "/"
    };
  },
  methods: {
    // 刷新页面保持激活样式
    fetchData() {
      var cur_path = this.$route; //获取当前路由
      var routers = this.$router.options.routes[1].children; // 获取路由对象
      routers.forEach(item => {
        if (item.name == cur_path.name) {
          this.defaultActiveIndex = cur_path.meta.munuName
        }
      })
    }
  },
  watch: {
    '$route': 'fetchData'
  },
};
</script>

<style scoped lang="less">
.content {
  .logo {
    width: auto;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }

  .el-menu {
    border: none;
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
    // height: 100vh;
  }

  .is-active {
    background: #e9eef3;
  }
}
</style>
