<template>
  <div class="content">
    <div class="icon">
      <!-- <div class="leftBox">
        <i :class="icon" @click="changeIcon"></i>
        <BreadVue></BreadVue>
      </div> -->
      <div class="leftBox">
        <img src="~@/assets/images/logo.png" alt="">
        <span>《微短剧大赛》管理系统</span>
      </div>
    </div>
    <div class="login">
      <!-- 头像 -->
      <el-button type="primary" @click="handleCommand">
        <span>退出</span>
      </el-button>
    </div>
  </div>
</template>

<script>
// 导入面包屑
// import BreadVue from "../../Breadcrumb.vue";
export default {
  // components: {
  //   BreadVue,
  // },
  data() {
    return {
      isCollapse: false,
      icon: "el-icon-s-fold",
    };
  },
  methods: {
    //退出登录
    handleCommand() {
      localStorage.clear();
      sessionStorage.clear()
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .icon {
    font-size: 18px;
    // line-height: 60px;
    color: #ccc;
    display: flex;
    align-items: center;
    cursor: pointer;

    .leftBox {
      display: flex;
      align-items: center;

      img {
        width: 126px;
        height: 39px;
        margin-right: 16px;
      }

      span {
        color: #454545;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }

  .login {
    height: 60px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .el-button {
    padding: 8px 20px;
  }
}
</style>
