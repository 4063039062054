<template>
  <div class="layer">
    <el-container>
      <el-header>
        <Header></Header>
      </el-header>
      <el-container>
        <el-aside class="layer_aside">
          <Menu></Menu>
        </el-aside>
        <el-main class="layer_main">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import login from "@/views/login/login";
import Header from "@/components/layer/header/header";
import Menu from "@/components/layer/menu/menu";
// import home from "@/views/home/home";
export default {
  name: "layerPage",
  components: {
    // login,
    Header,
    Menu,
    // home,
  },
};
</script>

<style lang="less" scoped>
// .layer {
//   width: 100%;
//   height: 100vh;
//   background: #ebf0f5;
//   background: #f0f3f5;
// }
// .el-container {
//   height: calc(110vh - 100px);
// }
// .el-header {
//   height: 60px;
//   // padding: 34px 20px;
// }
.layer {
  width: 100%;
  height: 100%;

  >.el-container {
    width: 100%;
    height: 100vh;

    .el-aside {
      width: auto !important;
      // box-shadow: 1px 0 6px rgb(0 21 41 / 35%);
    }

    .el-container {
    overflow-y: hidden;
      .el-header {
        color: #333;
        height: 25%;
      }

      .el-main {
        background-color: #e9eef3;
        padding: 20px 14px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
